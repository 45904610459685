import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useI18next } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"

const Tv = ({ data }) => {
  const { t } = useTranslation()

  const { language } = useI18next()

  const [idiomaTitulo, setIdiomaTitulo] = useState("")

  useEffect(() => {
    if (language === "es") {
      setIdiomaTitulo("tituloespanol")
    } else if (language === "ca") {
      setIdiomaTitulo("titulocatalan")
    } else if (language === "en") {
      setIdiomaTitulo("tituloingles")
    } else if (language === "fr") {
      setIdiomaTitulo("titulofrances")
    }
  }, [language])

  const television = data.television.nodes
  const schema = {
    "@context": t("SEO.television.schema.context"),
    "@type": t("SEO.television.schema.type"),
    name: t("SEO.television.schema.name"),
    description: t("SEO.television.schema.description"),
    url: t("SEO.television.schema.url"),
    image: t("SEO.television.schema.image"),
    sameAs: t("SEO.television.schema.sameAs"),
    jobTitle: t("SEO.television.schema.jobTitle"),
  }

  const datos = {
    title: t("SEO.television.datos.title"),
    description: t("SEO.television.datos.description"),
    lang: language,
  }

  return (
    <Layout schema={schema} datos={datos}>
      <section className="container max-w-7xl mt-10 sm:mt-16 px-10">
        <h2 className="border-t border-b text-center py-4 text-3xl sm:text-4xl uppercase">
          {t("television")}
        </h2>
        <article className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 mt-5">
          {television.map((caratula, index) => (
            <div
              key={index}
              className="flex flex-col justify-between bg-gris bg-opacity-5 p-3"
            >
              <div className="flex flex-col justify-end mb-2">
                <h2 className="text-blanco sm:text-xl">
                  {caratula[idiomaTitulo]}
                </h2>
                <h3>{caratula.director}</h3>
                <h4>{caratula.fecha}</h4>
              </div>
              <GatsbyImage
                image={getImage(caratula.imagen.localFile)}
                className="w-full rounded border-gris border-2"
                alt="catarula de television"
              />
            </div>
          ))}
        </article>
      </section>
    </Layout>
  )
}

export default Tv

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    television: allStrapiPosttv(sort: { fields: fecha, order: DESC }) {
      nodes {
        director
        fecha
        imagen {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        titulocatalan
        tituloespanol
        titulofrances
        tituloingles
      }
    }
  }
`
